import config from '../config.json'

export function isObjectEmpty(object) {
  if (object === null) {
    return false
  }
  return Object.keys(object).length === 0 && object.constructor === Object
}

export function getRandomArrayItem<T>(items: T[]): T {
  if (!items.length) {
    return null
  }
  return items[Math.floor(Math.random() * items.length)]
}

export function getFileFullPath(filePath: string, subFolder = ''): string {
  if (!filePath) {
    return ''
  }

  const baseUrl =
    config.env === 'test'
      ? 'https://some-random-bucket-name-123411.s3.eu-central-1.amazonaws.com/'
      : 'https://d239aqhc1m39lr.cloudfront.net/'
  return baseUrl + subFolder + encodeURIComponent(filePath)
}

export function isLinkForVideo(link: string): boolean {
  if (!link || typeof link !== 'string') {
    return false
  }

  const videoExtensions = ['.avi', '.mp4']
  return Boolean(videoExtensions.find((extension) => link.endsWith(extension)))
}

export function formatUrl(url: string): string {
  return url.replace(/\s+/g, '-').toLowerCase()
}

export function getPageRoute(name: string): string {
  return name.toLowerCase() === 'naslovna'
    ? ''
    : encodeURIComponent(name.replace(/\s+/g, '-').toLowerCase())
}

/* eslint-disable @typescript-eslint/camelcase */
export function isPostNew(isNew, published_at) {
  //fix this
  // return Boolean(isNew) && moment().diff(published_at, 'hours') < 4
  return Boolean(isNew && published_at)
}

// Extracting data from YT link
export function getQueryParameterByName(name, url) {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getYoutubeThumbnailImageUrl(videoUrl) {
  return `https://img.youtube.com/vi/${getQueryParameterByName(
    'v',
    videoUrl
  )}/maxresdefault.jpg`
}

export function numberWithSpaces(x: number | undefined): string {
  if (x === undefined) return ''
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

/*
export function numberWithSpaces(x: number): string {
  if (!x) return
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
*/

//Image placeholder banner
export const banerImagePlaceholder1040 = `./Reklamni-baner-1040x202px.jpg`
export const banerImagePlaceholder384 = `./Reklamni-baner-384x50px.jpg`
